
import { DomainAuApi, EmptyDomainAuResponseError } from '@/api';
import BotAvatar from '@/components/bot/BotAvatar.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import { SelectOption } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import {
  BotDomain,
  DeepPartial,
  DomainAuAgencySummary,
  DomainAuAgentSummary,
  DomainAuDomainData,
  DomainData,
} from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';

type DomainComAuSettings = Pick<DomainAuDomainData, 'type' | 'agencies' | 'agents'>;

@Component({
  name: 'domain-com-au-settings-card',
  components: { BotAvatar },
})
export default class DomainComAuSettingsCard extends mixins(BotMixin) {
  domainComAuSettings: DomainComAuSettings = this.resetValue();

  searchQuery = '';
  searchResults: Array<DomainAuAgencySummary | DomainAuAgentSummary> = [];

  isLoadingEntities = false;

  isSubmitting = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.domainComAuSettings, this.resetValue());
  }

  get typeSelectOptions(): SelectOption[] {
    return [
      {
        label: 'Agent(s)',
        value: 'agents',
      },
      {
        label: 'Agency(ies)',
        value: 'agencies',
      },
      {
        label: 'Both',
        value: 'all',
      },
    ];
  }

  get searchResultsWithoutConnected() {
    return this.searchResults.filter((result) =>
      (result as DomainAuAgentSummary).agentId
        ? !this.domainComAuSettings.agents.some(
            (agent) =>
              agent.agentId.toString() === (result as DomainAuAgentSummary).agentId.toString(),
          )
        : !this.domainComAuSettings.agencies.some(
            (agency) => agency.id.toString() === (result as DomainAuAgencySummary).id.toString(),
          ),
    );
  }

  get searchResultOptions(): SelectOption[] {
    return this.searchResultsWithoutConnected.map((result) => ({
      value: `${(result as DomainAuAgentSummary).agentId ? 'agentId' : 'id'}:${
        (result as DomainAuAgentSummary).agentId || (result as DomainAuAgencySummary).id
      }`,
      label: result.name,
      image:
        (result as DomainAuAgencySummary).logoUrl || (result as DomainAuAgentSummary).thumbnail,
    }));
  }

  async search() {
    if (!this.searchQuery || !this.domainComAuSettings.type) return;
    this.isLoadingEntities = true;
    try {
      if (this.domainComAuSettings.type === 'all') {
        const loadedData = await Promise.all([
          DomainAuApi.queryAgents(this.searchQuery),
          DomainAuApi.queryAgencies(this.searchQuery),
        ]);
        this.searchResults = [...loadedData[0], ...loadedData[1]];
      } else {
        this.searchResults = await DomainAuApi[
          this.domainComAuSettings.type === 'agents' ? 'queryAgents' : 'queryAgencies'
        ](this.searchQuery);
      }
      await this.$nextTick();
      this.focusAndToggleSearchResultSelect();
    } catch (e) {
      if (e instanceof EmptyDomainAuResponseError) {
        this.$notify.warning('No search-results were found for that query');
      } else {
        this.$notify.error({
          title: 'Failed to load search-results from Domain.com.au',
          description: GENERIC_ERROR_DESCRIPTION,
        });
      }
    } finally {
      this.isLoadingEntities = false;
    }
  }

  deleteConnection(key: 'agents' | 'agencies', value: number) {
    const index = this.domainComAuSettings[key].findIndex(
      (agentOrAgency: DomainAuAgencySummary | DomainAuAgentSummary) =>
        (agentOrAgency as any)[key === 'agents' ? 'agentId' : 'id'].toString() === value.toString(),
    );
    if (index >= 0) {
      this.domainComAuSettings[key].splice(index, 1);
    }
  }

  onSelectSearchResult(value: string) {
    const [type, id] = value.split(':');
    const selectedSearchResult = this.searchResults.find(
      (result) => (result as any)?.[type]?.toString() === id,
    );
    if (!selectedSearchResult) {
      return;
    }
    this.domainComAuSettings[type === 'id' ? 'agencies' : 'agents'].push(
      selectedSearchResult as any,
    );
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBot({
        id: this.bot.id,
        data: {
          domainData: {
            [BotDomain.RealEstate]: {
              domainAu: this.domainComAuSettings,
            },
          } as DeepPartial<DomainData>,
        },
      });
      this.$notify.success('Successfully updated Domain.com.au-settings');
    } catch (e) {
      // TODO maybe implement different handling if the error was caused by a timeout
      console.log(e);
      this.$notify.error({
        title: 'Updating Domain.com.au-settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  private focusAndToggleSearchResultSelect() {
    (this.$refs.searchResultSelect as any)?.focus();
    (this.$refs.searchResultSelect as any)?.open();
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.domainComAuSettings = this.resetValue();
  }

  private resetValue(): DomainComAuSettings {
    return {
      type: this.bot.domainData[BotDomain.RealEstate]?.domainAu?.type || '',
      agents: this.bot.domainData[BotDomain.RealEstate]?.domainAu?.agents?.slice() || [],
      agencies: this.bot.domainData[BotDomain.RealEstate]?.domainAu?.agencies?.slice() || [],
    };
  }
}
