
import DomainComAuSettingsCard from '@/components/real-estate/DomainComAuSettingsCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-real-estate-domain-com-au',
  components: { DomainComAuSettingsCard },
})
export default class ProjectRealEstateDomainComAu extends mixins(ProjectViewMixin) {}
